export const data = [
  {
    question: 'Popular_question_1_',
    answer: 'Popular_answer_1_',
  },
  {
    question: 'Popular_question_2_',
    answer: 'Popular_answer_2_',
  },
  {
    question: 'Popular_question_3_',
    answer: 'Popular_answer_3_',
  },
  {
    question: 'Popular_question_4_',
    answer: 'Popular_answer_4_',
  },
  {
    question: 'Popular_question_5_',
    answer: 'Popular_answer_5_',
  },
  {
    question: 'Popular_question_6_',
    answer: 'Popular_answer_6_',
  },
];
