import React from 'react';

import {
  PoolSellersContainer,
  PoolTableContainer,
} from 'containers';

export const PoolPage = () => (
  <div>
    <PoolSellersContainer />
    <PoolTableContainer />
  </div>
);
