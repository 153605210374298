export const data = [
  {
    percent: 8,
    lvl: 1,
  },
  {
    percent: 5,
    lvl: 2,
  },
  {
    percent: 5,
    lvl: 3,
  },
  {
    percent: 6,
    lvl: 4,
  },
  {
    percent: 7,
    lvl: 5,
  },
];
