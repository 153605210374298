import { emailRegex } from 'appConstants';

export const passwordValidator = (password: string) => {
  if (!password) {
    return 'The "password" field is required.';
  } if (
    password.length < 8 ||
    password.length > 16 ||
    !/[A-Z]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[!@#$%^&*]/.test(password)
  ) {
    return 'Sign_in_password_8_symbol_';
  }
  return '';
};

export const emailValidator = (email: string) => {
  if (!email) {
    return 'Email field is required';
  } if (!new RegExp(emailRegex).test(email)) {
    return 'Sign_in_invalid_email_';
  }
  return '';
};
