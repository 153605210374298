import React, { FC, ReactNode } from 'react';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import cn from 'classnames';

import 'react-tabs/style/react-tabs.css';
import styles from './styles.module.scss';

interface ISelectTabsProps {
  tabTitles: string[][] | string[],
  tabContents?: ReactNode[],
  defaultActiveTab?: number,
  onSelect?: (n: number) => void,
  classNameTabContent?: string,
}

export const SelectTabs: FC<ISelectTabsProps> = ({
  tabTitles,
  tabContents,
  defaultActiveTab,
  onSelect,
  classNameTabContent,
}) => (
  <Tabs
    defaultIndex={defaultActiveTab || 0}
    className={styles.tabs}
    onSelect={onSelect}
  >
    <TabList className={styles.tabs_titles}>
      {tabTitles.map((titles, index) => (
        <Tab
          key={titles[0]}
          className={cn(styles.tabs_titles_title, {
            [styles.tabs_titles_title_active_forever]: defaultActiveTab === index,
          }, classNameTabContent)}
          selectedClassName={cn({
            [styles.tabs_titles_title_active]: defaultActiveTab !== index,
          })}
        >
          {typeof titles === 'string' ? titles : titles.map((i) => (
            <div>
              {i}
            </div>
          ))}

        </Tab>
      ))}
    </TabList>

    {tabContents && tabContents.map((content) => (<TabPanel>{content}</TabPanel>))}
  </Tabs>
);
