export const cardsTexts = [
  {
    text: 'Video_call_li_1_',
    id: 1,
  },
  {
    text: 'Video_call_li_2_',
    id: 2,
  },
  {
    text: 'Video_call_li_3_',
    id: 3,
  },
  {
    text: 'Video_call_li_4_',
    id: 4,
  },
  {
    text: 'Video_call_li_5_',
    id: 5,
  },
  {
    text: 'Video_call_li_6_',
    id: 6,
  },
];
