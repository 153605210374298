export class ApiError extends Error {
  status = 0;
  error = '';
  text = '';

  constructor(message: string, status: number, error: string, text: string) {
    super(message);
    this.status = status;
    this.error = error;
    this.text = text;
  }
}
