import React from 'react';

import {
  StatusSellersContainer,
  StatusTableContainer,
} from 'containers';

export const StatusPage = () => (
  <div>
    <StatusSellersContainer />
    <StatusTableContainer />
  </div>
);
