import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { Colors } from 'appConstants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      titleColor: Colors.TEXT_COLOR_BLUE,
      bodyColor: Colors.TEXT_COLOR_BLUE,
      backgroundColor: Colors.WHITE,
      borderRadius: 16,
      borderWidth: 2,
      borderColor: Colors.GRAY,
      padding: 12,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: Colors.TEXT_COLOR,
      },
      border: {
        color: Colors.GRAY,
      },
    },
    y: {
      grid: {
        drawTicks: false,
        color: Colors.GRAY,
      },
      border: {
        display: false,
      },
      ticks: {
        min: 0,
        max: 100,
        stepSize: 25,
        color: Colors.TEXT_COLOR,
      },
    },
  },
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const ChartBar = ({
  total,
  personal,
  referral,
  date,
}: {
  total: number[],
  personal: number[],
  referral: number[],
  date: string[],
}) => {
  const { t } = useTranslation('main');
  const trans = useTranslation('partner').t;

  const getMonthIndex = useCallback((monthString: string) => (
    months.findIndex((month) => monthString.toLowerCase().includes(month.toLowerCase()))
  ), []);

  const startMonth = getMonthIndex(date[0] || 'jan'); // Это вернет индекс 3 для апреля

  const allMonths = useMemo(() => (
    [
      t('Jan'),
      t('Feb'),
      t('Mar'),
      t('Apr'),
      t('May'),
      t('Jun'),
      t('Jul'),
      t('Aug'),
      t('Sep'),
      t('Oct'),
      t('Nov'),
      t('Dec'),
    ]
  ), [t]);
  const labels = useMemo(() => (
    allMonths.slice(startMonth).concat(allMonths.slice(0, startMonth))
  ), [allMonths, startMonth]);

  const rearrangeData = (data: number[], start: number) => (
    data.slice(start).concat(data.slice(0, start))
  );

  return (
    <Bar
      options={options}
      data={{
        labels,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        datasets: [
          {
            label: trans('Total_profit_bonus_'),
            data: rearrangeData(total, startMonth),
            backgroundColor: 'rgba(102, 115, 189, 1)',
            borderRadius: 2,
            barThickness: 8,
          },
          {
            label: trans('Personal_profit_char_'),
            data: rearrangeData(personal, startMonth),
            backgroundColor: 'rgba(249, 143, 99, 1)',
            borderRadius: 2,
            barThickness: 8,

          },
          {
            label: trans('Profit_from_referrals_'),
            data: rearrangeData(referral, startMonth),
            backgroundColor: 'rgba(5, 22, 85, 1)',
            borderRadius: 2,
            barThickness: 8,
          },
        ],
      }}
    />
  );
};
